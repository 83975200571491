import { useEffect, useState } from 'react'
import { Title, Container, Input, Button, Ticket, Number, Hora } from './styled'
import Loader from './Loader'
import ticket from './../assets/ticket.png'
import banner from './../assets/banner.jpg'
import './../App.css'
import { format } from 'date-fns'
import { postQuery, compare } from './../processes/Queries.tsx'

const Resultados = () => {
    let numbers = []
    for(let i = 0; i < 1000; i++){
        let number = ''
        if(i < 10){number = `00${i}`}
        else if(i < 100){number = `0${i}`}
        else if(i < 1000){number = `${i}`}
        numbers.push(number)
    }
    const [loading, setloading] = useState(false)
    const [data, setData] = useState({
        date: format(new Date(), 'yyyy-MM-dd'),
        horarios: []
    })
    const search = (value) => {
        let payload = {
            fecha: value,
            id_tipo_loteria: 118
        }
        setloading(true)
        postQuery(payload).then(res => {
            setloading(false)
            setData({date: value, horarios: compare(res.data)})
        })
        .catch(err=>{
            setloading(false)
            console.log(err)
        })
    }
    useEffect(()=>{
        let payload = {
            fecha: data.date,
            id_tipo_loteria: 118
        }
        setloading(true)
        postQuery(payload).then(res => {
            setloading(false)
            setData((state)=>({...state, horarios: compare(res.data)}))
        }).catch(err=>{
            setloading(false)
            console.log(err)
        })
    },[])
    return(
        <Container top={50} width={100} direction='column'>
            {loading ? <div className='loader-container'>
                <Loader />
            </div>: null}
            <Container top={50}>
                <Title>
                    Resultados Triple Facíl
                </Title>
            </Container>
            <Container top={50} width={80} gap={10}>
                <Input type="date" width={40} 
                value={data.date} onChange={(e)=>search(e.target.value)}/>
            </Container>
            <Container top={50} width={90} gap={10}>
                {data.horarios.map(h => 
                <Container top={10} width={20} key={h.hora_sorteo}
                className='ticket' direction='column'>
                    <Hora className='hora'
                    >{h.loteria}</Hora>
                    <Ticket src={ticket}/>
                    <Number>{h.numero || '???'}</Number>
                </Container>)}
            </Container>
            {/* <Container top={50} width={80}>
                <img src={banner} className='banner' alt=''
                style={{ width: '50%' }}/>
            </Container> */}
            <Container top={50} width={80}>
                <Title>
                    Nuestros Triples
                </Title>
            </Container>
            <Container top={50} width={90} gap={10}>
                {numbers.map(n => 
                <Container top={10} width={20} key={n}
                className='ticket'>
                    <Ticket src={ticket}/>
                    <Number>{n}</Number>
                </Container>)}
            </Container>
            
        </Container>
    )
}

export default Resultados