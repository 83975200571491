import { Header } from './components/styled'
import Resultados from './components/Resultados'
import logo from './assets/logo.png'
import './App.css'

function App() {
  return (
    <>
      <Header>
          <img src={logo} alt="Triple Facil" 
          className='logo'/>
      </Header>
      <Resultados/>
    </>
  )
}

export default App
