import styled from 'styled-components'

export const Header = styled.header`
    background-color: #0168B3;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px #000;
`

export const Title = styled.h1`
    color: #fff;
    font-size: 24px;
    font-weight: bolder;
`

export const Container = styled.div`
    width: ${props => `${props.width}%` || '100%'};
    margin-top: ${props => `${props.top}px` || '0'};
    flex-direction: ${props => props.direction || 'row'};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${props => `${props.gap}px` || '0px'};
    background-color: ${props => props.background || 'transparent'};
    flex-wrap: wrap;
`

export const Input = styled.input`
    width: ${props => `${props.width}%` || '100%'};
    margin-top: ${props => `${props.top}px` || '0'};
    background-color: #fff;
    color: #0168B3;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
`

export const Button = styled.button`
    width: ${props => `${props.width}%` || '100%'};
    margin-top: ${props => `${props.top}px` || '0'};
    background-color: ${props => props.color || '#0168B3'};
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: 2px solid transparent;
    transition: all .3s ease;
    &:hover{
        cursor: pointer;
        background-color: transparent;
        color: #0168B3;
        border-color: #0168B3;
    }
`

export const Ticket = styled.img`
    width: 150px;
`

export const Number = styled.span`
    position: absolute;
    font-size: 20px;
    bottom: 26px;
`

export const Hora = styled.span`
    font-size: 20px;
    margin-bottom: 5px;
    color: #fff;
`