import axios from 'axios'

const url = 'https://apuestanext.com/apuestanext.com/aplicativo/accion/apis/resultados.php'

export const horarios = [
    {hora_sorteo:"08:00",loteria:"Triple Facil 08:00 AM",numero:null},
    {hora_sorteo:"09:00",loteria:"Triple Facil 09:00 AM",numero:null},
    {hora_sorteo:"10:00",loteria:"Triple Facil 10:00 AM",numero:null},
    {hora_sorteo:"11:00",loteria:"Triple Facil 11:00 AM",numero:null},
    {hora_sorteo:"12:00",loteria:"Triple Facil 12:00 AM",numero:null},
    {hora_sorteo:"13:00",loteria:"Triple Facil 01:00 PM",numero:null},
    {hora_sorteo:"14:00",loteria:"Triple Facil 02:00 PM",numero:null},
    {hora_sorteo:"15:00",loteria:"Triple Facil 03:00 PM",numero:null},
    {hora_sorteo:"16:00",loteria:"Triple Facil 04:00 PM",numero:null},
    {hora_sorteo:"17:00",loteria:"Triple Facil 05:00 PM",numero:null},
    {hora_sorteo:"18:00",loteria:"Triple Facil 06:00 PM",numero:null},
    {hora_sorteo:"19:00",loteria:"Triple Facil 07:00 PM",numero:null},
]

export const postQuery = (data) => {
    return axios.post(url, data)
}

export const compare = (data) => {
    horarios.forEach((h:any) => {
        const horario = data.find((l:any) => l.hora_sorteo == h.hora_sorteo)
        horario ? h.numero = horario.numero : h.numero = null
    })
    return horarios
}